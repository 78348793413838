import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppCustomPreloadingStrategy } from './app-routing.preload-strategy';
import { TenantGuard } from './core/guards/tenant.guard';
import { LandlordGuard } from './core/guards/landlord.guard';
import { UserIdentityService } from './core/authentication/services/user-identity.service';
import { User } from './core/authentication/models/user';
import { RoleGuard } from './core/guards/role.guard';
import { RoleBasedDashboardComponent } from './shared/components/role-based-dashboard/role-based-dashboard.component';
import { LayoutComponent } from './layout/main/layout/layout.component';
import { RoleBasedPreloadingStrategy } from './role-based-routing.preload-strategy';
import { UserTypeGuard } from './core/guards/UserTypeGuard';
import { PageNotFoundComponent } from './shared/views/page-not-found/page-not-found.component';
import { LoginComponent } from './core/authentication/components/login/login.component';
import { ForgottonPasswordComponent } from './landlord/features/anonymous/components/forgotton-password/forgotton-password.component';
import { RegisterComponent } from './landlord/features/anonymous/components/register/register.component';
import { ResetPasswordComponent } from './landlord/features/users/components/reset-password/reset-password.component';
import { LoggedOutLayoutComponent } from './layout/logged-out/layout/logged-out-layout.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   canActivate: [AuthGuard, UserTypeGuard],
  //   children: []
  // },
  {
    path: '',
    loadChildren: () => import('./landlord/landlord.module').then(m => m.LandlordModule),
    canActivate: [AuthGuard],
    // canActivate: [RoleGuard],
    data: { requiredRole: ['landlord'], preloadForLandlord: true }
  },
  {
    path: 'tenant',
    loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule),
    canActivate: [AuthGuard],
    // canActivate: [RoleGuard],
    data: { requiredRole: ['tenant'], preloadForTenant: true }
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./core/authentication/authentication.module').then(m => m.AuthenticationModule)
  // },
  
  {
    path: '',
    component: LoggedOutLayoutComponent,
    children: [
      // { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () =>
          import('@core/authentication/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'login',
         component: LoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgottonPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      // { path: 'logout', component: LogoutComponent },
      // { path: 'login', component: LoginComponent },
      // Add other routes for non-logged-in users here
    ],
  },
  
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    bindToComponentInputs: true,
    preloadingStrategy: RoleBasedPreloadingStrategy
    // preloadingStrategy: AppCustomPreloadingStrategy
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
  currentUser: User | undefined;
  constructor(
    private userIdentityService: UserIdentityService,
  ) {
    this.currentUser = userIdentityService.currentUser();
  }
}
