<div class="card border-primary h-100" [appClickableRow]="['/property', property.id]">

  <div class="image-container">
    <img [src]="mainPropertyImageUrl" alt="Property Image" class="img-fluid">
  </div>

  <!-- <img [src]="mainPropertyImageUrl" class="card-img-top " alt="Property Image"
    style="width: 100%; height: 150px; object-fit: contain; object-position: center; padding-top: 10px;"> -->
  <div class="card-body">
    <h6 class="">
      {{property.fullAddress}}
    </h6>
    <h6 class="text-primary">Rent: £{{ property.price | number:'1.2-2' }}</h6>

  </div>
  <div class="card-footer bg-primary text-white d-flex justify-content-between">
    <span class="p-1 fw-bold border border-2 rounded">
      <span class="me-2"><i class="fas fa-bed"></i> {{ property.bedrooms || 0 }}</span>
      <span class="me-2"><i class="fas fa-bath"></i> {{ property.bathrooms || 0}}</span>
    </span>
      <span class="text-white p-1 fw-bold border border-2 rounded">
      {{property.status}}
    </span>
    <!-- <h5>
      <span class="badge m3"
        [ngClass]="{'bg-light': property.status == 'Active',  'bg-danger': property.status != 'Active'}">
        {{property.status}}
      </span>
    </h5> -->
  </div>
</div>