import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import {  ReactiveFormsModule } from '@angular/forms';

import { AddressBookRoutingModule } from './address-book-routing.module';
import { SearchComponent } from './components/search/search.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    AddressBookRoutingModule,
    TypeaheadModule.forRoot(),
    NgSelectModule,
  ],
  exports: [
    SearchComponent
  ],
})
export class AddressBookModule { }
