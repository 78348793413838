<div class="container">
  <main role="main" class="pb-3">
    <div class="container">
      <div class="row justify-content-center text-center mt-4">
        <img
          src="/assets/images/logo.png"
          style="min-width: 150px;min-height: 150px; width: 10vw; height: 10vh; display: block; margin-bottom: 20px;"
          alt="Company Logo"
        />
        <h1 class="mb-3 col-12">
          <strong>404 - Page Not Found</strong>
        </h1>
        <p class="col-12">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <div class="col-12 mt-4">
          <a href="/" class="btn btn-primary">Go to Homepage</a>
        </div>
      </div>
    </div>
  </main>
</div>
