import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { AuthModule } from './core/authentication/auth.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './layout/main/layout.main.module';
import { LoggedOutLayoutModule } from './layout/logged-out/logged-out-layout.main.module';
import { CoreModule } from '@core/core.module';
import { AnonymousModule } from '@landlord/features/anonymous/anonymous.module';
import { SharedModule } from './shared/shared.module'; // Add this line

// 3rd party Components
import { NgSelectModule } from '@ng-select/ng-select';// Add this line
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery'
import { DragDropModule } from '@angular/cdk/drag-drop';

//ngx
import { TabsModule } from 'ngx-bootstrap/tabs';
import {  AlertConfig } from 'ngx-bootstrap/alert';
import {BsDatepickerModule,BsDatepickerConfig,} from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse'; // And this line
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingSpinnerService } from './shared/services/loading-spinner.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FileUploadModule } from '@iplab/ngx-file-upload';

//datepicker
import {  BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale} from 'ngx-bootstrap/locale';
defineLocale('en-gb', enGbLocale);

// app components
import { ToastrModule } from 'ngx-toastr';
import { DropdownService } from './core/services/dropdown.service';
import { provideGlobalGridOptions } from 'ag-grid-community';

// Add error handling logging via Rollbar
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar/rollbar.service';
import { JwtModule } from '@auth0/angular-jwt';
import { AppCustomPreloadingStrategy } from './app-routing.preload-strategy';
import { LandlordGuard } from './core/guards/landlord.guard';
import { TenantGuard } from './core/guards/tenant.guard';
import { SubscriptionInterceptor } from './core/interceptor/subscription.interceptor';
import { AgGridModule } from 'ag-grid-angular';
import { HttpErrorResponseInterceptor } from './core/interceptor/http-error-response.interceptor';

export async function initializeApp(dropdownService: DropdownService) {
  if(DropdownService.isInitialised) return;
  return async () => await dropdownService.initialise();
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    PhotoGalleryModule,
    FormsModule,
    RouterModule.forRoot([], {
      preloadingStrategy: AppCustomPreloadingStrategy,
      // enableTracing: true 
    }),
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    LayoutModule,
    AuthModule,
    LoggedOutLayoutModule,
    CoreModule,
    NgSelectModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    AnonymousModule,
    FileUploadModule,
    DragDropModule,
    AgGridModule,
    JwtModule.forRoot({
      config: {},
    }),
    SharedModule, // Add SharedModule here
  ],
  // ... rest of the file remains unchanged ...
  exports: [

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { 
      provide: HTTP_INTERCEPTORS,
       useClass: HttpErrorResponseInterceptor,
        multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubscriptionInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      deps: [DropdownService],
      multi: true
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: provideGlobalGridOptions,
      useValue: {
        localeText: {
          // Example of locale text configuration
          noRowsToShow: 'No data available',
          // Other locale text settings
        },
        // TODO Other global grid options - this is not working !!
        pagination: true,
        paginationPageSize: 10,
        rowHeight: 30,
        paginationPageSizeSelector: [10, 20, 50, 100],
        filter: true,
        resizable: true
      }
    },
    AlertConfig,
    BsDatepickerConfig,
    BsDropdownConfig,
    LoadingSpinnerService,
    LandlordGuard,
    TenantGuard,
  ],
  // providers: [PropertyService],
  bootstrap: [AppComponent],
})


export class AppModule {

  constructor(
    private bsLocaleService: BsLocaleService,
  ){
    this.bsLocaleService.use('en-gb');//use the uk english locale for ngx-datepicker

  }


}
