import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@src/app/core/services/user.service';
import { ResetPasswordViewModel } from '@src/app/models/users/ResetPasswordViewModel';
import { FormComponent } from '@src/app/shared/components/form/form/form.component';

@Component({
  selector: 'tlp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends FormComponent implements OnInit {
  form: UntypedFormGroup = new FormGroup({});
  resetPasswordViewModel: ResetPasswordViewModel = new ResetPasswordViewModel(); // This should be replaced with the actual type

  constructor(
    private fb: FormBuilder,
     private userService: UserService,
      private route: ActivatedRoute,
       private toastr: ToastrService
      )
  {
    super();
  }


  override ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.resetPasswordViewModel.verificationCode = params['verificationCode'] || '';
    });
    this.generateForm();
    super.ngOnInit();
  }

  public message: string = '';

  public generateForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],//, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]//,, Validators.minLength(8)]],
    });
  }

  public validationMessages = {
    email: {
      required: 'Email is required',
      email: 'Email must be a valid email address',
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be at least 8 characters long',
      weakPassword: 'Password must contain at least one uppercase letter, one number, and one special character',
      mismatch: 'Passwords do not match',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minlength: 'Confirm Password must be at least 8 characters long',
      mismatch: 'Passwords do not match',
    },
  };

  public onSubmit(): void {
    if (!this.validateForm()) {
      this.getErrorMessages().map((message) => {
        // Replace this with your preferred way of showing error messages
        console.error(message);
      });
      return;
    }
    // get the form values
    this.resetPasswordViewModel.email = this.form.value.email;
    this.resetPasswordViewModel.password = this.form.value.password;

    this.userService.resetPassword(this.resetPasswordViewModel).subscribe({
      next: () => {
        // Handle successful password reset
        const message = 'Your password has been reset successfully!';
        this.toastr.success(message);
        this.message = message;
      },
      error: (error) => {
        // Handle error
        console.error('Error resetting password:', error);
      },
    });
  }

  public isSubmitDisabled(): boolean {
    return false;
    const password = this.form.get('password')?.value;
    const confirmPassword = this.form.get('confirmPassword')?.value;
    return !password || !confirmPassword || password !== confirmPassword;
  }
}
