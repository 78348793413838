// import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import * as Rollbar from 'rollbar'; // is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.
import { environment } from '@src/environments/environment';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';

const rollbarConfig = {
  environment: 'production',
  accessToken: 'b061495e2bf3401ca24988cbaf23eea2',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.production ? 'production' : 'development',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
    custom: { 
      // hostname: window.URL,
      userAgent: navigator.userAgent,
      browserVersion: navigator.appVersion,
    },
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
