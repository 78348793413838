import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserIdentityService } from '@core/authentication/services/user-identity.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private authService: UserIdentityService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      // console.log('AuthGuard: Testing route', state.url);
      if (!this.authService.isAuthenticated()) {
        console.log('AuthGuard: User not authenticated, navigating to login');
        let redirectUrl = (state.url.includes('login')) ?  '/' : state.url;
        // Store the intended URL as a query parameter
        this.router.navigate(['login'], { queryParams: { returnUrl: redirectUrl }});
        return false;
      }
      // console.log('AuthGuard: User authenticated, allowing route');
      return true;
  }
}