
<!-- <button id="global-search-button-1" (click)="toggleSearchBox()" class="bg-secondary" draggable="true" (dragstart)="onDragStart($event)" (dragend)="onDragEnd($event)">
  <i class="fa fa-search"></i>
</button> -->

<div id="global-search-box" class="global-search-box rounded-3 ms-2 me-2">
  <div class="input-group-disabled">
    <input #searchInput type="text" class="form-control" [(ngModel)]="searchQuery" placeholder="Search..." (keydown)="onKeydown($event)">
    <!-- <div class="input-group-append">
      <button class="btn btn-default" (click)="performSearch()">
        <i class="fa fa-search"></i>
      </button>
    </div> -->
  </div>
</div>
