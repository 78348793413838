<div class="container col-12 col-md-6 mt-3">
  <div class="card">
  <div class="card-header text-bg-primary">
    <span>Reset Password</span>
  </div>
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="col-xs-12 ">
        <control-wrapper [control]="form.controls['email']" name="email" label="Email" [validationErrors]="validationMessages.email">
          <input id="email" formControlName="email" type="email" class="form-control">
        </control-wrapper>


        <control-wrapper [control]="form.controls['password']" name="password" label="New Password" [validationErrors]="validationMessages.password">
          <input id="password" formControlName="password" type="password" class="form-control">
        </control-wrapper>


        <control-wrapper [control]="form.controls['confirmPassword']" name="confirmPassword" label="Confirm Password" [validationErrors]="validationMessages.email">
          <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control">
        </control-wrapper>

        <!-- <tlp-password [control]="form.controls['password']" [validationErrors]="validationMessages.password" label="Password"></tlp-password>
        <tlp-password [control]="form.controls['confirmPassword']" [validationErrors]="validationMessages.confirmPassword" label="Confirm Password"></tlp-password> -->

        <div class="clearfix">
          <div class="col mb-3 d-flex justify-content-center">
            <button type="submit" class="btn btn-primary m-3" [disabled]="isSubmitDisabled()">Reset Password</button>
          </div>
        </div>
        <!-- <div class="alert alert-info mt-3">
          <p>Password must meet the following criteria:</p>
          <p>At least 8 characters long</p>
          <p>Contains at least one uppercase letter</p>
          <p>Contains at least one number</p>
          <p>Contains at least one special character (e.g., @$!%*?&)</p>
        </div> -->
      </div>
    </form>
      <div *ngIf="message" class="alert alert-success mt-3">
        {{ message }}
      </div>
    </div>
  </div>
</div>
