<div class="search-component">
  <form [formGroup]="searchForm">
    <div class="search-container">
      <ng-select 
        [items]="suggestions$ | async"
        [multiple]="multiple"
        [maxSelectedItems]="maxSelectedItems"
        bindLabel="displayName"
        (change)="onSelect($event)"
        [clearable]="false"
        [searchable]="true"
        placeholder="Search contacts"
        [loading]="(isLoading$ | async) ?? false"
        [typeahead]="searchInput$"
        formControlName="search"
        (open)="onNgSelectClick()">
        <ng-template ng-option-tmp let-item="item">
          {{item.displayName}}
        </ng-template>
        <ng-template ng-loadingtext-tmp>
          <div class="ng-spinner-loader"></div>
        </ng-template>
        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div *ngIf="(isLoading$ | async) === false && (hasClicked$ | async) === true">
            No contacts found for "{{searchTerm}}"
          </div>
        </ng-template>
      </ng-select>
    </div>
  </form>

  <div class="alert alert-danger" role="alert" *ngIf="errorMessage$ | async as error">
    {{ error }}
  </div>
</div>
