import { Component, Input } from '@angular/core';
import { LettingPropertySummary } from '@src/app/models/LettingPropertySummary';

@Component({
  selector: 'tlp-property-summary-card',
  templateUrl: './property-summary-card.component.html',
  styleUrls: ['./property-summary-card.component.css']
})
export class PropertySummaryCardComponent {

  @Input()
  property: LettingPropertySummary = {} as LettingPropertySummary;
  @Input()
  baseUrl: string = '';

  // a getter for the image url
  public get mainPropertyImageUrl() {
    if (this.property.mainImageFilepath)
      return this.baseUrl + '/api/images/' + (this.property.mainImageFilepath) + '?height=150'
    else
      return '/assets/images/house-placeholder.jpg';
  }

  ngOnInit(): void {
    // console.log(this.property);
  }
}
