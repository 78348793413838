import { Injectable } from '@angular/core';
import { errorMessages } from '../constants/error-messages';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorInfo, ErrorService } from '../services/error.service';
import { APIResponse } from '@src/app/models/APIResponse';
import { ApiException } from '@src/app/models/system/ApiException';

// Catches errors from the server and replaces them with error messages to be shown in the UI.
@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => { },
        (response: any) => { 
          
          const apiResponse = response?.error as APIResponse<ApiException>;
          const apiException = apiResponse?.data as ApiException;
          var errorInfo = new ErrorInfo();
          if (apiException && apiException.hasOwnProperty('errorCode')) {

          errorInfo.statusCode = apiException.httpStatusCode;
          errorInfo.message = apiException.message;
          }

          if(response?.errors?.length > 0){
            errorInfo.message = response.errors[0];
          }

          switch (response.status)
          {
            case 204: // No content
              //this.errorService.setApiError(errorInfo);
              break;
          }

          if (response instanceof HttpErrorResponse) {
            switch (response.status) {
              case 401:
                this.errorService.setApiError(errorInfo);
                break;
              case 400:
                if(response.error  && response.error.errors) {
                  // Handle validation errors
                  const validationErrors = this.extractValidationErrors(response.error.errors);
                  // You can now do something with these validation errors, like storing them in a service
                  console.log('Validation Errors:', validationErrors);
                  errorInfo.message = validationErrors.join('\n');
                }
                return throwError(() => errorInfo);
                this.errorService.setApiError(errorInfo);
                break;
              case 404: // Not found
              case 204: // No content
                this.errorService.setApiError(errorInfo);
                 this.router.navigate(['404'], { skipLocationChange: true });
                // return of(undefined); // Return an empty observable to cancel the request
                // this.router.navigate(['404'], { skipLocationChange: true });
                break;
            }
          }
          return throwError(() => errorInfo);
        }
      )
    );
  }

  private extractValidationErrors(errors: any): string[] {
    const extractedErrors: string[] = [];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        extractedErrors.push(...errors[key]);
      }
    }
    return extractedErrors;
  }
}
