export class ContractorJobStatus {
  static NotSet = 0;
  static New = 1;
  static InProgress = 2;
  static Completed = 3;
  static UnableToComplete = 4;
  static Cancelled = 5;

  static toDisplayName(value: ContractorJobStatus): string {
    switch (value) {
      case ContractorJobStatus.NotSet:
        return 'Not Set';
      case ContractorJobStatus.New:
        return 'New';
      case ContractorJobStatus.InProgress:
        return 'In Progress';
      case ContractorJobStatus.Completed:
        return 'Completed';
      case ContractorJobStatus.UnableToComplete:
        return 'Unable To Complete';
      case ContractorJobStatus.Cancelled:
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  }
}
