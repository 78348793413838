import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner';
import { ModalDialogComponent } from './components/modal/modal-dialog/modal-dialog.component';
import { ModalFormFooterComponent } from './components/modal/modal-form-footer/modal-form-footer.component';
import { ModalFormHeaderComponent } from './components/modal/modal-form-header-component/modal-form-header.component';
import { AddTenancyButtonComponent } from './components/add-tenancy-button/add-tenancy-button.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { AddImageButtonComponent } from './components/add-image-button/add-image-button.component';
import { ImageLazyLoadingDirective } from './directives/image-lazy-loading.directive';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { CoreModule } from '../core/core.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ContactTypeaheadComponent } from './components/contact-typeahead/contact-typeahead.component';
import { NgxCurrencyDirective } from './directives/currency-mask/ngx-currency.directive';
import { CurrencyPipe } from '@angular/common';
import { ClickableRowDirective } from './directives/clickable-row.directive';
import { FileUploadPartialComponent } from './components/file-upload-partial/file-upload-partial.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PropertySummaryCardComponent } from './components/property-summary-card/property-summary-card.component';
import { AccessControlComponent } from './components/access-control/access-control.component';
import { AddressBookModule } from '../landlord/features/address-book/address-book.module';
import { RoleBasedDashboardComponent } from './components/role-based-dashboard/role-based-dashboard.component';
import { RouterModule } from '@angular/router';
import { HomeModule } from "../landlord/features/home/home.module";
import { ViewsModule } from './views/views.module';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { TinyMceComponent } from './components/tiny-mce/tiny-mce.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';
@NgModule({
  declarations: [
    ValidationErrorComponent,
    BackButtonComponent,
    LoadingSpinnerComponent,
    ModalDialogComponent,
    ModalFormFooterComponent,
    ModalFormHeaderComponent,
    AddTenancyButtonComponent,
    // AddImageButtonComponent,
    AddressLookupComponent,
    AddImageButtonComponent,
    ImageLazyLoadingDirective,
    ValidationErrorComponent,
    ContactTypeaheadComponent,
    ClickableRowDirective,
    FileUploadPartialComponent,
    PropertySummaryCardComponent,
    AccessControlComponent,
    TinyMceComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TypeaheadModule.forRoot(),
    ReactiveFormsModule,
    NgxCurrencyDirective,
    CoreModule,
    FileUploadModule,
    AddressBookModule,
    ViewsModule,
    EditorModule,
    NgSelectModule,
],
  providers: [
    CurrencyPipe
  ],
  exports: [
    ValidationErrorComponent,
    ImageLazyLoadingDirective,
    NgxCurrencyDirective,
    AddTenancyButtonComponent,
    LoadingSpinnerComponent,
    AddImageButtonComponent,
    ValidationErrorComponent,
    AddressLookupComponent,
    BackButtonComponent,
    ContactTypeaheadComponent,
    ClickableRowDirective,
    FileUploadPartialComponent,
    ModalDialogComponent,
    PropertySummaryCardComponent,
    AccessControlComponent,
    PageNotFoundComponent,
    TinyMceComponent,
  ],
})
export class SharedModule {}
