<div class="container">
  <div class="row justify-content-center text-center mt-4">
      <img
          src="/assets/images/logo.png"
          style="min-width: 150px;min-height: 150px; width: 10vw; height: 10vh; display: block; margin-bottom: 20px;"
          alt="Company Logo"
      />
      <h1 class="mb-3 col-12">
          <strong>403 - Not Authorised</strong>
      </h1>
      <p class="col-12">You do not have permission to access this page. </p>
      <p>Please check your credentials or contact the administrator if you believe this is an error.</p>
      <div class="col-12 mt-4">
          <a href="/" class="btn btn-primary me-2">Go to Homepage</a>
          <!-- <a href="/login" class="btn btn-primary">Login</a> -->
      </div>
  </div>
</div>
