import { Injectable } from '@angular/core';
import { AuthenticationEventsService } from './authentication-events.service';
import { User } from '../models/user';
import * as moment from 'moment';
import { LocalStorageService } from 'angular-web-storage';
import { LoginResponse } from '../models/LoginResponse';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

// Class for querying and updating the logged in user data.
@Injectable()
export class UserIdentityService {

  private user: User ;

  constructor(
    private authEventsService: AuthenticationEventsService,
    private localStorageService: LocalStorageService,
    private jwtHelper: JwtHelperService,
  ) {
    this.user = this.localStorageService.get('currentUser');
    this.refreshUser();
    // // Pull current user from local storage.
    // this.user = this.localStorageService.get('currentUser');

    // // Flush out the user data if it is not valid or access token is expired.
    // const validAuthenticatedUser = this.user && this.user.userName && this.user.accessToken && moment() < moment(this.user.expiryTime);
    // if (!validAuthenticatedUser) {
    //   this.user;
    //   this.localStorageService.set('currentUser', undefined);
    // }
    // this.authEventsService.userUpdatedEvent.trigger(this.user);
  }

  refreshUser() : void {
    // Pull current user from local storage.
    this.user = this.localStorageService.get('currentUser');

    // Flush out the user data if it is not valid or access token is expired.
    const validAuthenticatedUser = this.user && this.user.userName && this.user.accessToken && moment() < moment(this.user.expiryTime);
    if (!validAuthenticatedUser) {
      this.user;
      this.localStorageService.set('currentUser', undefined, );
    }
    this.authEventsService.userUpdatedEvent.trigger(this.user);

  }

  isAuthenticated(): boolean {
    let user = this.currentUser();
    return !!user && user.accessToken != undefined && (moment() < moment(user.expiryTime));
  }

  createUserFromLoginResponse(response: LoginResponse): boolean{
    if (response?.token) {
      let user = Object.assign(new User(), response);
      user.accessToken = response.token;
      // Update the user in memory and local storage.
      localStorage.setItem('token', response.token);
      localStorage.setItem('user-id', response.userId);
      localStorage.setItem('agency-id', response.agencyId);
      this.updateUser(user, true);
      return true;
    }
    return false;
  }

  currentUser(): User | undefined {
    // Pull current user from local storage.
    let userFromStorage = this.localStorageService.get('currentUser') as User;
    if(userFromStorage==null)
      return userFromStorage;

    this.user = userFromStorage;
    this.user.roles = this.getUserRoles();
    let thisUser = new User();
    thisUser.accessToken = this.user.accessToken;
    thisUser.roles = this.user.roles;
    thisUser.userName = this.user.userName;
    thisUser.expiryTime = this.user.expiryTime;
    thisUser.userId = this.user.userId;
    return thisUser;
    return this.user;
  }

  // Updates user in memory and in local storage - use undefined for no user.
  updateUser(user: User, notifyListeners = true): User {
    this.user = user;
    this.localStorageService.set('currentUser', user);
    if (notifyListeners) {
      this.authEventsService.userUpdatedEvent.trigger(user);
    }
    return user;
  }

  // Removes the current user from memory and local storage.
  logout(){
    // remove all cookies
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('user-id');
    localStorage.removeItem('agency-id');
  }

  // Get the access token or trigger the login required event if there is not a valid token.
  getAccessToken(): string | undefined {
    const currentTime = moment();
    const expired = this.user && moment().isAfter(this.user.expiryTime);
    if (!this.user || !this.user.accessToken || expired) {
      this.authEventsService.loginRequiredEvent.trigger({ expired: expired });
      return ;
    } else {
      return this.user.accessToken;
    }
  }

  // Using angular-jwt to decode the token.
  private decodeToken(token: string): any {
    return this.jwtHelper.decodeToken(token);
  }

  // Get the user roles from the token.
  getUserRoles(): string[] {
    if (!this.user)
      return [];
    const decodedToken = this.decodeToken(this.user.accessToken);
    if (!decodedToken) return [];
    const role = decodedToken.role;
    return Array.isArray(role) ? role : (role ? [role] : []);
  }

  // Check if the user is in a role.
  isInRole(role: string): boolean {
    const userRoles = this.getUserRoles();
    return userRoles.map(r => r.toLowerCase()).includes(role.toLowerCase());
  }

  // Check if the user is in any of the roles.
  hasRole(roles: string[]): boolean {
    const userRoles = this.getUserRoles();
    return roles.some(role => userRoles.map(r => r.toLowerCase()).includes(role.toLowerCase()));
  }


  // isLandlord(): boolean {
  //   // Pull current user from local storage.
  //   return this.isInRole('landlord');
  // }

  // isTenant(): boolean {
  //   // Pull current user from local storage.
  //   return this.isInRole('tenant');
  // }

}
