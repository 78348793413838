import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Visibility } from '@src/app/core/enums/Visibility';
import { AccessControl } from '@src/app/models/accessControl';
import { ContactSearch } from '@src/app/models/contactSearch';

@Component({
  selector: 'tlp-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.css']
})
export class AccessControlComponent {

  Visibility = Visibility;
  members: ContactSearch[] = [];
  accessControl = new AccessControl();
  visibility: Visibility = Visibility.Unrestricted;
  form: UntypedFormGroup = new FormGroup({});
  @Output()
  public contactSelected = new EventEmitter<ContactSearch>();
  @Output()
  public selectedItems = new EventEmitter<any[]>();
  @Output()
  public updateACL = new EventEmitter<AccessControl>();


  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.generateForm();
    this.emitAccessControl(); // Emit initial value
  }

  public generateForm(): void {
    this.form = this.fb.group({
      contact: [''],
      visibility: [Visibility.Unrestricted.toString(), Validators.required],
    });
  }

onVisibilityChange(event: any): void {
  const selectedValue = parseInt(event.target.value, 10);
  this.visibility = selectedValue as Visibility;

  // this.form.patchValue({ visibility: this.visibility });
  // Emit the updated AccessControl if needed
  this.emitAccessControl();
}
private emitAccessControl(): void {
  this.accessControl.visibility = Number(this.form.get('visibility')?.value ?? 0);
   
  this.updateACL.emit({...this.accessControl});
  // console.log('Emitting AccessControl:', {...this.accessControl});
}

onContactSelected(contact: ContactSearch): void {
  this.accessControl.members.push(contact);
  this.emitAccessControl();
}

onSelectedContacts(contacts: ContactSearch[]): void {
  this.accessControl.members = contacts
  this.emitAccessControl();
}


}
