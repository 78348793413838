import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tlp-app-base',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.css']
})

export abstract class AppBaseComponent {

  // Recursively find a parameter in the route hierarchy.
  findParameter(route: ActivatedRoute, paramName: string): string | null {
   if (route.snapshot.paramMap.has(paramName)) {
     return route.snapshot.paramMap.get(paramName);
   } else if (route.parent) {
     return this.findParameter(route.parent, paramName);
   } else {
     return null;
   }
 }

 //recursively find a parameter in the route hierarchy.
  findData(route: ActivatedRoute, paramName: string): string | null {
   if (route.snapshot.paramMap.has(paramName)) {
     return route.snapshot.paramMap.get(paramName);
   } else if (route.parent) {
     return this.findData(route.parent, paramName);
   } else {
     return null;
   }
 }

   // Helper method to create a FormData object from a data object.
   createFormData(data: any, formData: FormData = new FormData(), parentKey: string = ''): FormData {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (value instanceof File) {
          formData.append(fullKey, value);
        } else if (Array.isArray(value)) {
          if (value.length > 0 && value[0] instanceof File) {
            for (let i = 0; i < value.length; i++) {
              
              if(value[i] != null || value[i] != undefined)
                formData.append(fullKey, value[i]);
            }
          } else {
            for (let i = 0; i < value.length; i++) {
              this.createFormData(value[i], formData, `${fullKey}[${i}]`);
            }
          }
        } else if (typeof value === 'object' && value !== null) {
          this.createFormData(value, formData, fullKey);
        } else {
          if(value != null || value != undefined)
            formData.append(fullKey, value);
        }
      }
    }
    return formData;
  }

}
