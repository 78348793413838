import { Component } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginResponse } from '../../models/LoginResponse';
import { UserIdentityService } from '@core/authentication/services';
import { User } from '@core/authentication/models';
import { SettingsService } from '@src/app/core/services/settings.service';
import { APIResponse } from '@src/app/models/APIResponse';
import { BaseApiService } from '@src/app/core/services/base-api.service';
import { map } from 'rxjs';
import { UserService } from '@src/app/core/services/user.service';
import { LoadingSpinnerService } from '@src/app/shared/services/loading-spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email: string  = ''; // defult for testing
  password: string  = ''; // default for testing
  showPassword: boolean = false; // to toggle password visibility
  returnUrl: string = '/';
  errors: string[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {
    // Subscribe to queryParams to get the returnUrl
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'] || '';
    });
  }

  togglePasswordVisibility(){
    this.showPassword = !this.showPassword;
  }

  login() {
    const body = {
      email: this.email,
      password: this.password,
    };

    // const params = new HttpParams().set('returnUrl', this.returnUrl);
    // const loginUrl = this.settingsService.get().loginUri;//'https://localhost:44301/account/login';
this.loadingSpinnerService.show();
    this.userService.login(this.email, this.password).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.router.navigateByUrl(returnUrl);
          // check that the returnUrl isn't this address
          // if (this.returnUrl === this.router.url || this.returnUrl.includes('login')) {
          //   this.returnUrl = '';
          // }
          // this.router.navigateByUrl(this.returnUrl);

        } else {
          this.errors = response.errors;
        }
      },
      error: (error) => {
        console.error('Login failed:', error);
      },
      complete: () => {
        this.loadingSpinnerService.hide();
      }
    });


    // this.http
    //   .post<APIResponse<LoginResponse>>(loginUrl, body, {
    //     params: params,
    //     // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    //     observe: 'response',
    //   })
    //   .subscribe({
    //     next: (response) => {
    //       if (response.status === 200) {
    //         if (response.body?.isSuccess) {
    //           let user = this.authService.createUserFromLoginResponse(response.body.data);
    //           if(user){
    //             this.authService.updateUser(user);
    //             this.router.navigateByUrl(this.returnUrl);
    //           }
    //         } else {
    //           this.errors = response.body!.errors;
    //           this.errors = response.body!.errors;
    //         }
    //       }
    //     },
    //     error: (error) => {
    //       this.errors = [error.error.message || 'Login failed'];
    //       console.error('Login failed:', error);
    //     },
    //   });

  }
}
