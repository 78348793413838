import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit } from '@angular/core';
import { SearchService } from '@core/services/search.service';
import { ContactSearch } from '@src/app/models/contactSearch';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'tlp-contact-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  suggestions$: Observable<ContactSearch[]>;
  errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() contactSelected = new EventEmitter<ContactSearch>();
  @Output() selectedItems = new EventEmitter<ContactSearch[]>();
  @Input() multiple: boolean = false;
  @Input() maxSelectedItems: number = 1;

  searchInput$ = new Subject<string>();
  searchForm: FormGroup;

  constructor(private searchService: SearchService, private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search: ['']
    });
    this.suggestions$ = this.initializeTypeahead();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log('Component view initialized');
  }

  initializeTypeahead(): Observable<ContactSearch[]> {
    return this.searchInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.isLoading$.next(true)),
      switchMap(term => this.searchService.searchAddressBook(term).pipe(
        tap(() => this.isLoading$.next(false)),
        catchError(error => {
          console.error('Error loading contacts:', error);
          this.errorMessage$.next('Failed to load contacts. Please try again.');
          this.isLoading$.next(false);
          return [];
        })
      )),
      map(data => data.map(contact => ({
        ...contact,
        displayName: `${contact.firstName} ${contact.lastName} (${contact.email})`
      })))
    );
  }

  // events - contactSelected and selectedItems
  // if multiple is true then emit an array of selected contacts as contactSelected
  // if multiple is false then emit a single contact as contactSelected and also the array of selected contacts as selectedItems
  onSelect(event: ContactSearch | ContactSearch[]) {
    let selectedContacts: ContactSearch[];
  
    if (Array.isArray(event)) {
      // event is already an array
      selectedContacts = event;
    } else {
      // event is a single object, wrap it in an array
      selectedContacts = [event as ContactSearch];
      this.contactSelected.emit(event as ContactSearch); // Pass the single object directly  
    }
  
    // Now selectedContacts is always an array
    this.selectedItems.emit(selectedContacts);
  }


  onNgSelectClick(): void {
    this.hasClicked$.next(true);
    // Trigger the typeahead logic by emitting an empty string
    this.searchInput$.next('');
  }
}
