import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { PropertyGlobalSearch } from '@src/app/models/propertyGlobalSearch';
import { ContactSearch } from '@src/app/models/contactSearch';
import { TenancyGlobalSearch } from '@src/app/models/tenancyGlobalSearch';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _baseService: BaseApiService) {  }

  // returns a list of properties that match the search query
  findLettingProperties(query: string): Observable<PropertyGlobalSearch[]> {
    const url = `/api/search/FindLettingProperties?q=${query}`;
    return this._baseService.get<PropertyGlobalSearch[]>(url);
  }

  // returns a list of properties that match the search query
  findSalesProperties(query: string): Observable<PropertyGlobalSearch[]> {
    const url = `/api/search/FindSalesProperties?q=${query}`;
    return this._baseService.get<PropertyGlobalSearch[]>(url);
  }
  
  // returns a list of properties that match the search query
  findTenancies(query: string): Observable<TenancyGlobalSearch[]> {
    const url = `/api/search/FindTenancies?q=${query}`;
    return this._baseService.get<TenancyGlobalSearch[]>(url);
  }
  // returns a list of properties that match the search query
  searchAddressBook(query: string): Observable<ContactSearch[]> {
    const url = `/api/search/Contacts?q=${query}`;
    return this._baseService.get<ContactSearch[]>(url);
  }
}
