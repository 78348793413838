import { Component } from '@angular/core';

@Component({
  selector: 'tlp-not-authorised',
  templateUrl: './not-authorised.component.html',
  styleUrls: ['./not-authorised.component.css']
})
export class NotAuthorisedComponent {

}
