import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private apiErrorSubject = new BehaviorSubject<ErrorInfo | null>(null);
  apiError$: Observable<ErrorInfo | null> = this.apiErrorSubject.asObservable();

  setApiError(error: ErrorInfo | null) {
    this.apiErrorSubject.next(error);
  }

  hasApiError(): Observable<boolean> {
    return this.apiError$.pipe(map(error => error !== null));
  }

  getApiError(): Observable<ErrorInfo | null> {
    return this.apiError$;
  }
}

export class    ErrorInfo {
  statusCode: number = 200;
  message: string = '';
}