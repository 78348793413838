<ul class="nav flex-column" accordion>
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" href="/">
      <span class="nav-link-icon"><span class="fa fa-area-chart"></span></span><span
        class="nav-link-text ps-2">Dashboard</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/property" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-list"></span></span><span
          class="nav-link-text ps-2">Properties</span>
      </div>
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link" routerLink="/property/add" role="button" data-bs-toggle="collapse" aria-expanded="false"
      (click)="onMenuItemClick()" aria-controls="authentication">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-plus"></span></span><span class="nav-link-text ps-2">Add
          Property</span>
      </div>
    </a>
  </li>
</ul>


<ul class="nav flex-column">
  <li class="nav-item">
    <div class="nav-link" (click)="isMaintenanceMenuCollapsed = !isMaintenanceMenuCollapsed"
      [attr.aria-expanded]="!isMaintenanceMenuCollapsed" aria-controls="maintenanceMenu" role="button">
      <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span
        class="nav-link-text ps-2">Maintenance</span>
    </div>
  </li>
  <li class="nav-item ms-3" id="maintenanceMenu" [collapse]="isMaintenanceMenuCollapsed">
    <a class="nav-link" routerLink="/maintenance" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span
          class="nav-link-text ps-2">All</span>
      </div>
    </a>
    <a class="nav-link" routerLink="/maintenance/new" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span
          class="nav-link-text ps-2">New</span>
      </div>
    </a>
    <a class="nav-link" routerLink="/maintenance/not-assigned" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span class="nav-link-text ps-2">Not
          Assigned</span>
      </div>
    </a>
    <a class="nav-link" routerLink="/maintenance/in-progress" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span class="nav-link-text ps-2">In
          Progress</span>
      </div>
    </a>
    <a class="nav-link" routerLink="/maintenance/active" (click)="onMenuItemClick()">
      <div class="nav-link-content">
        <span class="nav-link-icon"><span class="fa fa-wrench"></span></span><span
          class="nav-link-text ps-2">Active</span>
      </div>
    </a>
  </li>
</ul>
