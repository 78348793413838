import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserIdentityService } from '@src/app/core/authentication/services';
import { User } from '@core/authentication/models';
import { UserService } from '@src/app/core/services/user.service';
import { UserProfile } from '@src/app/models/userProfile';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebarComponent implements OnInit {

  isAdminMenuCollapsed = true;
  private showAddressBook:boolean = false;
  smallScreen = false;
  @Input() isCollapsed: boolean = true;
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter();
  public currentUser: User | undefined;
  public currentUserProfile : UserProfile = new UserProfile();

  constructor(
    private userIdentityService: UserIdentityService,
    private userService: UserService,
   ) {
    this.currentUser = userIdentityService.currentUser();
    if(this.currentUser){
      userService.get(this.currentUser.userId).subscribe((userProfile) => {
        this.currentUserProfile = userProfile;
      });
    }
  };


  public toggle(): void {
    this.isCollapsed = !this.isCollapsed;
    this.toggleSidebar.emit();
  }

  public onMenuItemClick(event: Event = {} as Event): void {
    if (event.target) {
      let currentElement = event.target as HTMLElement;
      while (currentElement) {
        if (currentElement.tagName === 'A') {
          // The event.target has an anchor ancestor
          break;
        }
        currentElement = currentElement.parentElement! ;
      }
      if(currentElement === null){
        return;
      }
    }
    if (window.innerWidth < 768) {
      this.isCollapsed = true;
      this.smallScreen = true;
      this.toggleSidebar.emit();
    }
  }

  public onWindowResize(): void {
    if (window.innerWidth >= 768) {
      this.isCollapsed = false;
    }else{

    this.smallScreen = true;
    }
  }
  ngOnInit(): void {
    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.onWindowResize(); // Set initial state based on window size
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onWindowResize.bind(this));
  }
}

