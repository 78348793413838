import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { EditorComponent } from '@tinymce/tinymce-angular';

// https://chat.mistral.ai/chat/9e52a43d-7b12-4aa3-860e-42ceb3253a3a

@Component({
  selector: 'tlp-tiny-mce',
  templateUrl: './tiny-mce.component.html',
  styleUrls: ['./tiny-mce.component.css']
})
export class TinyMceComponent  implements AfterViewInit {
  @ViewChild('ngSelect', { static: false }) ngSelect!: ElementRef;
  @ViewChild('editor', { static: false }) editor!: EditorComponent;

  fieldNames = ['field1', 'field2', 'field3', 'field4'];
  showSelect = false;
  selectedField: string | null = null;

  init: EditorComponent['init'] = {
    base_url: '/assets/tinymce/js/tinymce', // Updated path to TinyMCE assets
    suffix: '.min',
    plugins: 'lists link image table code help wordcount',
    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
    menubar: false,
    height: 500,
    license_key: 'gpl',
    promotion: false,
    content_css: '/assets/tinymce/js/tinymce/skins/content/default/content.min.css',
    skin_url: '/assets/tinymce/js/tinymce/skins/ui/oxide',
    setup: (editor) => {
      editor.on('keyup', (e) => {
        let content = editor.getContent({ format: 'text' });
        let cursorPos = editor.selection.getRng().startOffset;
        let prefix = content.substring(cursorPos - 2, cursorPos);

        if (prefix === '{{') {
          this.showSelect = true;
          setTimeout(() => {
            const rect = editor.getContainer().getBoundingClientRect();
            const ngSelectElement = this.ngSelect.nativeElement;
            ngSelectElement.style.display = 'block';
            ngSelectElement.style.position = 'absolute';
            ngSelectElement.style.left = `${rect.left + window.scrollX}px`;
            ngSelectElement.style.top = `${rect.bottom + window.scrollY}px`;
            ngSelectElement.focus();
          }, 0);
        } else {
          this.showSelect = false;
        }
      });

      editor.on('click', () => {
        this.showSelect = false;
      });
    }
  };

  ngAfterViewInit() {
    // Ensure the editor is initialized
    this.editor.editor.on('init', () => {
      this.editor.editor.setContent('');
    });
  }

  onSelectField(event: any) {
    const field = event.name; // Extract the selected value
    this.editor.editor.insertContent(field);
    this.showSelect = false;
  }
}