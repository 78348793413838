import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { SubscriptionLimitReachedComponent } from '@src/app/landlord/features/subscription/components/subscription-limit-reached/subscription-limit-reached.component';
import { ModalDialogComponent } from '@src/app/shared/components/modal/modal-dialog/modal-dialog.component';
import { AppDialogContext } from '@src/app/shared/models/app-modal-context';
import { BsModalService } from 'ngx-bootstrap/modal';
import { APIResponse } from '@src/app/models/APIResponse';
import { ApiException } from '@src/app/models/system/ApiException';
import { ErrorDetailCode } from '@src/app/models/system/ErrorDetailCode';
import { Router } from '@angular/router';

// This interceptor is used to catch the subscription specific errors from the Api
// and show the limit dialog to the user

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {

  constructor(
    private modalService: BsModalService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const apiResponse = error?.error as APIResponse<ApiException>;
        const apiException = apiResponse?.data as ApiException;
        if (error.status === 400 && apiException?.errorDetailCode ==  ErrorDetailCode.SubscriptionLimitReached) {
          this.showLimitDialog(error.error);
          return EMPTY;
        }
        return throwError(error);
      })
    )
  };

  private showLimitDialog(message: string) {
    
    const context: Partial<AppDialogContext> = {
      childComponent: SubscriptionLimitReachedComponent,
      title: 'The Landlord Portal',
      okBtnText: 'Show plans',
      cancelBtnText: 'Cancel',
      showHeaderClose: true,
      showOnlyCloseBtn: false,
      footerClass: 'custom-footer-class',
      headerClass: 'bg-primary text-white'
    };

    const config = AppDialogContext.generate(context);
    const modalRef = this.modalService.show(ModalDialogComponent, { initialState: { context: config } });

    // Listen to all events from the child component
    (modalRef.content as ModalDialogComponent).childEvent.subscribe(
      ({ eventName, data }) => {
        switch (eventName) {
          case 'ok':
            // ... handle ok event
            
            modalRef.hide(); // Close the modal
            this.router.navigate(['/pricing']); // Redirect to the desired 

            break;
          // ... handle other events as needed
        }
      }
    );
  }
}
