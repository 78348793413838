<h3>Access control</h3>

<form [formGroup]="form">
    
  <control-wrapper label="Visibility" [control]="form.controls['visibility']" >
    <div class="btn-group" role="group" aria-label="Visibility options">
      <input type="radio" class="btn-check" id="private" value="1" formControlName="visibility" (change)="onVisibilityChange($event)">
      <label class="btn btn-outline-primary" for="private">Landlord</label>

      <input type="radio" class="btn-check" id="SelectedContacts" value="2" formControlName="visibility" (change)="onVisibilityChange($event)">
      <label class="btn btn-outline-primary" for="SelectedContacts">Selected Contacts</label>

      <input type="radio" class="btn-check" id="unrestricted" value="0" formControlName="visibility" (change)="onVisibilityChange($event)">
      <label class="btn btn-outline-primary" for="unrestricted">Unrestricted</label>
    </div>
  </control-wrapper>

  <control-wrapper *ngIf="visibility == Visibility.SelectedContacts" label="Individual access" [control]="form.controls['contact']">
    <tlp-contact-search 
      (contactSelected)="onContactSelected($event)" 
      (selectedItems)="onSelectedContacts($event)"
      [multiple]="true" [maxSelectedItems]="20">
    </tlp-contact-search>
  </control-wrapper>
</form>
