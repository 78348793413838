
<div class="card">
  <div class="card-header text-bg-primary"  *ngIf="tenancyId == 0">
    <span *ngIf="tenancyId == 0">New Tenancy</span>
    <!-- <span *ngIf="tenancyId > 0">Edit Tenancy</span> -->

    <back-button class="float-end "></back-button>
  </div>
  <div class="card-body">

<form [formGroup]="form" id="create-tenancy-form" novalidate>

  <div class="col-12 col-lg-8">
    <input formControlName="id" type="hidden" />
    <input formControlName="propertyId" type="hidden" />
    <!-- <input formControlName="renewalStatusId" type="hidden" /> -->

  <control-wrapper name="initialTermMonthsId" label="Initial Term" [control]="form.controls['initialTermMonthsId']"
  [validationErrors]="getValidationMessages('initialTermMonthsId')">
    <select class="form-select" formControlName="initialTermMonthsId"
    [class.is-invalid]="isControlInvalid('initialTermMonthsId')">
      <option value=""></option>
      <option *ngFor="let month of dropdowns.initialTermOptions" [value]="month.value">
        {{month.text}}
      </option>
    </select>
  </control-wrapper>

  <control-wrapper name="leaseTypeId" label="Lease Type" [control]="form.controls['leaseTypeId']" [validationErrors]="getValidationMessages('leaseTypeId')">
    <select class="form-select" formControlName="leaseTypeId" [class.is-invalid]="isControlInvalid('leaseTypeId')">
      <option value=""></option>
      <option *ngFor="let type of dropdowns.leaseTypeOptions" [value]="type.value">
        {{type.text}}
      </option>
    </select>
  </control-wrapper>

  <control-wrapper name="termTypeId" label="Term Type" [control]="form.controls['termTypeId']" [validationErrors]="getValidationMessages('termTypeId')">
    <select class="form-select" formControlName="termTypeId"  [class.is-invalid]="isControlInvalid('termTypeId')">
      <option value=""></option>
      <option *ngFor="let type of dropdowns.termTypeOptions" [value]="type.value">{{type.text}}</option>
    </select>
  </control-wrapper>

  <control-wrapper name="statusId" label="Status" [control]="form.controls['statusId']"  [validationErrors]="getValidationMessages('status')">
    <select class="form-select" formControlName="statusId" [class.is-invalid]="isControlInvalid('statusId')">
      <option value=""></option>
      <option *ngFor="let status of dropdowns.statusOptions" [value]="status.value">{{status.text}}</option>
    </select>
  </control-wrapper>

  <control-wrapper [control]="form.controls['dateStart']" label="Start Date:"  [validationErrors]="getValidationMessages('dateStart')">
    <div class="input-group cursor-pointer">
      <input type="text" class="form-control" bsDatepicker #startDp="bsDatepicker" formControlName="dateStart"
       placeholder="Start Date" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default' }" [class.is-invalid]="isControlInvalid('dateStart')">
      <span class="input-group-text" (click)="startDp.toggle()"><i class="fa fa-calendar"></i></span>
    </div>
  </control-wrapper>

  <control-wrapper [control]="form.controls['dateEnd']" label="Initial End Date:"  [validationErrors]="getValidationMessages('dateEnd')">
    <div class="input-group cursor-pointer" >
      <input type="text" class="form-control" bsDatepicker #endDp="bsDatepicker" formControlName="dateEnd" placeholder="End Date"
      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default'  }"  [class.is-invalid]="isControlInvalid('dateEnd')">
      <span class="input-group-text" (click)="endDp.toggle()"><i class="fa fa-calendar"></i></span>
    </div>
  </control-wrapper>

  <control-wrapper name="rentAmount" label="Rent Amount" [control]="form.controls['rentAmount']" [validationErrors]="getValidationMessages('rentAmount')">
    <input type="text" formControlName="rentAmount" class="form-control" [class.is-invalid]="isControlInvalid('rentAmount')">
  </control-wrapper>

  <control-wrapper name="rentFrequencyId" label="Rent Frequency" [control]="form.controls['rentFrequencyId']">
    <select class="form-select" formControlName="rentFrequencyId" [class.is-invalid]="isControlInvalid('rentFrequencyId')">
      <option value=""></option>
      <option *ngFor="let freq of dropdowns.rentFrequency" [value]="freq.value">{{freq.text}}</option>
    </select>
  </control-wrapper>

  <control-wrapper name="depositAmount" label="Deposit Amount" [control]="form.controls['depositAmount']" [validationErrors]="getValidationMessages('depositAmount')">
    <input type="text" class="form-control col-sm-9" formControlName="depositAmount"
    [class.is-invalid]="isControlInvalid('depositAmount')">
  </control-wrapper>

  <control-wrapper name="additionalRequirements" label="Additional Requirements"
    [control]="form.controls['additionalRequirements']">
    <textarea class="form-control col-sm-9" formControlName="additionalRequirements" rows="3"
    [class.is-invalid]="isControlInvalid('additionalRequirements')"></textarea>
  </control-wrapper>
  </div>

  <div class="clearfix">
    <div class="col mb-3">
      <div class="float-end ">
        <button type="button" class="btn btn-primary ajax-submit m-3"   (click)="onSubmit()">
          <i class="fa"></i>
          <span *ngIf="tenancyId == 0">Add New Tenancy</span>
          <span *ngIf="tenancyId > 0">Update Tenancy</span>
        </button>
      </div>
    </div>
  </div>
</form>
    </div>
  </div>
