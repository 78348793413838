import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css'],
})
export class BackButtonComponent {

  constructor(private location: Location) { }
  goBack() {
     // Navigate back
     history.back();
    // window.location.href = document.referrer;
  }
}
