import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUpload } from '@src/app/models/fileUpload';
import { FileUploadArea } from '@src/app/core/enums/FileUploadArea';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { FormComponent } from '../form/form/form.component';

@Component({
  selector: 'tlp-file-upload-partial',
  templateUrl: './file-upload-partial.component.html',
  styleUrls: ['./file-upload-partial.component.css']
})
export class FileUploadPartialComponent extends FormComponent implements OnInit {
  @Input() lookupKey: string = '';
  @Input() fileTitle: string = '';
  @Input() fileDescription: string = '';
  @Input() fileTitleVisible: boolean = true;
  @Input() fileDescriptionVisible: boolean = true;
  @Input() area: FileUploadArea = FileUploadArea.General;
  @Output() fileUploads = new EventEmitter<FileUpload[]>();
  @Input() form: UntypedFormGroup = new FormGroup({});
  @Input() uploadLimit: number = 1;

  @Output() validationMessagesChange = new EventEmitter<{ [key: string]: { [key: string]: string } }>();

  public animation: boolean = false;
  public multiple: boolean = this.uploadLimit > 1;

  files: File[] = [];

  constructor(private fb: FormBuilder) {
    super();
  }

  override ngOnInit(): void {

    super.ngOnInit();
    this.validationMessagesChange.emit(this.validationMessages);
    if (!this.form || !(this.form instanceof UntypedFormGroup)) {
      throw new Error('A valid form of type UntypedFormGroup must be provided to FileUploadPartialComponent.');
    }

    // clear the form
    this.form.reset();
    this.generateForm();

    this.form.get('files')?.valueChanges.subscribe((files: File[]) => {
      if (files && files.length > 0) {
        this.form.get('title')?.setValue(files[0].name);
      }
      this.onFilesSelected();
    });
  }

  generateForm(): void {
    this.form.addControl('files', this.fb.control([], [Validators.required, FileUploadValidators.filesLimit(this.uploadLimit)]));
    if (!this.form.get('title')) {
      this.form.addControl('title', this.fb.control(this.fileTitle, Validators.required));
    }
    if (!this.form.get('description')) {
      this.form.addControl('description', this.fb.control(this.fileDescription, Validators.required));
    }
  }

  onFilesSelected(): void {
    const files: File[] = this.form.get('files')?.value;
    if (!files || files.length === 0) {
      return;
    }

    const fileUploads: FileUpload[] = files.map((file) => {
      let fileUpload = new FileUpload();
      fileUpload.area = this.area;
      fileUpload.lookupKey = this.lookupKey;
      fileUpload.title = this.form.get('title')?.value;
      fileUpload.description = this.form.get('description')?.value;
      fileUpload.Files = [file];
      return fileUpload;
    });

    this.fileUploads.emit(fileUploads);
  }

  onSubmit() {
    if (!this.validateForm()) {
      this.getErrorMessages().forEach((message) => {
        // this.toastr.error(message);
      });
      return;
    }
  }

  public validationMessages: { [key: string]: { [key: string]: string } } = {
    files: {
      required: 'File is required',
      filesLimit: `Maximum ${this.uploadLimit} file${this.uploadLimit > 1 ? 's' : ''} allowed`,
    },
    title: {
      required: 'Title is required',
    },
    description: {
      required: 'Description is required',
    },
  };
}
