import { FileStorageLocation } from "@core/enums/FileStorageLocation";
import { FileUploadArea } from "@core/enums/FileUploadArea";
import { BaseAuditModel } from "./baseAuditModel";
import { AccessControl } from "./accessControl";
import { Visibility } from "../core/enums/Visibility";

export class FileUpload extends BaseAuditModel {
  area: FileUploadArea = FileUploadArea.General;
  lookupKey: string = '';
  filePath: string = '';;
  contentType: string = '';;
  title: string = '';;
  description: string = '';;
  originalFilename: string = '';;
  isWordTemplate: string = '';;
  pulseFilename: string = '';;
  extension: string = '';;
  sortorder: number = 0;
  storageLocation: FileStorageLocation = FileStorageLocation.AWS;
  link: string = '';
  Files: File[] = [];  
  accessControl :  AccessControl = new AccessControl();
  visibilityId: Visibility = Visibility.Unrestricted;
  visibility: string = '';

}

